<template>
  <div>
    <div class="text-right flex">
      <button
        @click="handleDownload()"
        class="
          px-4
          py-2
          mb-4
          bg-gray-50
          border
          rounded-md
          text-balck
          flex
          items-center
        "
      >
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>

      <button
        @click="generatePdf()"
        class="
          px-4
          py-2
          mb-4
          ml-4
          bg-gray-50
          border
          rounded-md
          text-balck
          flex
          items-center
        "
      >
        <img class="h-5" src="../../../assets/pdf.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>

    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        <!-- {{ this.data[0].branch }} -->
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">{{
          $route.params.from | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">{{
          $route.params.to | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
    </div>
    <table id="my-table" class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Date
          </th>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Branch Trans No.
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Agents Trans No.
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Total Trans No.
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Branch Sales
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Agents Sales
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Total Sales
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Branch Commission
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Agents Commission
          </th>

          <th
            scope="col"
            class="
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Total Commission.
          </th>
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200 text-center">
        <tr v-for="(transaction, i) in data" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ i }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ transaction.branch_transactions }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              <b>{{ transaction.agent_transactions }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              <b>{{ transaction.total_transactions }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(transaction.branch_sales) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(transaction.agent_sales) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(transaction.total_sales) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-center">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{ formatPrice(transaction.branch_commission) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-center">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{ formatPrice(transaction.agent_commission) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-center">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{ formatPrice(transaction.total_commission) }}</b>
            </div>
          </td>
        </tr>

        <tr class="bg-gray-50">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  <b>TOTAL</b>
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ getTotalBranchTransactions() }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ getTotalAgentTransactions() }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ getTotalTransactions() }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ formatPrice(getTotalBranchSales()) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              GMD
              <b>{{ formatPrice(getTotalAgentSales()) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              GMD
              <b>{{ formatPrice(getTotalSales()) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(getTotalBranchCommission()) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(getTotalAgentCommission()) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-center">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{ formatPrice(getTotalCommission()) }}</b>
            </div>
          </td>
        </tr>

        <!-- More people... -->
      </tbody>
    </table>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
  props: ["data"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {
    totalTransactions: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.transactions); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    totalAmount: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.amount); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    totalCommission: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.commission); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    total: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.amount + val.commission); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    generatePdf() {
      // var doc = new jsPDF();

      // console.log("DOC", doc);

      // var vm = this;

      let results = this.data;

      var result = Object.values(results);

      console.log("Results", result);

      // var docName = `Reconciliation Report: ${moment(vm.selected.date).format(
      //   "dddd, MMMM Do YYYY"
      // )}`;

      // var fileName = `reconciliation-report-${moment(vm.selected.date).format(
      //   "dddd, MMMM Do YYYY"
      // )}`;

      // var columns = [
      //   { title: "Branch Trans No", dataKey: "branch_transactions" },
      //   { title: "Agents Trans No", dataKey: "agent_transactions" },
      //   { title: "Total Trans No.", dataKey: "total_transactions" },
      //   { title: "Branch Sales", dataKey: "branch_sales" },
      //   { title: "Agents Sales", dataKey: "agent_sales" },
      //   { title: "Total Sales", dataKey: "total_sales" },
      // ];

      // var doc = new jsPDF("p", "pt");

      const doc = new jsPDF({ orientation: "l" });
      // require("jspdf-autotable");
      doc.text(
        `From: ${moment(this.$route.params.from).format(
          "ddd, MMM Do YYYY"
        )} | To: ${moment(this.$route.params.to).format("ddd, MMM Do YYYY")}`,
        10,
        20
      );

      // autoTable()

      // doc.setLineWidth(1.0);
      // doc.line(40, 30, 560, 30);

      // doc.setFontSize(12);
      // doc.text(
      //   `Reconcile By: ${
      //     vm.selected.created_by
      //   }    |   Total: GMD ${this.formatPrice(
      //     this.total(vm.selected.collection_reconciles)
      //   )}`,
      //   40,
      //   50
      // );

      // doc.setLineWidth(1.0);
      // doc.line(40, 60, 560, 60);

      // doc.setFontSize(12)    ;
      // doc.text(`Remarks: ${vm.selected.remarks}`, 40, 80);
      doc.autoTable({ html: "#my-table", margin: { top: 30 } });
      // doc.autoTable({
      //   columns: columns,
      //   body: result,
      //   margin: { top: 20 },
      // });

      doc.save(`${this.getFileName()}.pdf`);
    },

    getTotalTransactions() {
      // this.data.map((trans) => console.log(trans.total_transactions));
      // console.log(this.data);
      // return this.data.map((trans) => trans.total_transactions);
      let result = 0;

      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        // console.log(`${key} ---- ${value.total_transactions}`);
        console.log(key);
        result += Number(value.total_transactions);
      }
      // console.log(result, "kkkkkkkkkkkk");
      return result;
    },
    getTotalBranchTransactions() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_transactions);
      }
      return result;
    },
    getTotalAgentTransactions() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_transactions);
      }
      return result;
    },
    getTotalBranchSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_sales);
      }
      return result;
    },
    getTotalAgentSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_sales);
      }
      return result;
    },
    getTotalSales() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.total_sales);
      }
      return result;
    },
    getTotalBranchCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.branch_commission);
      }
      return result;
    },
    getTotalAgentCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.agent_commission);
      }
      return result;
    },
    getTotalCommission() {
      let result = 0;
      for (const [key, value] of Object.entries(this.data)) {
        console.log(key);
        result += Number(value.total_commission);
      }
      return result;
    },

    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },

    handleDownload() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Date",
          "Branch Trans No.",
          "Agent Trans No.",
          "Total Trans No.",
          "Branch Sales",
          "Agent Sales",
          "Total Sales",
          "Branch Commission",
          "Agent Commission",
          "Total Commission",
        ];
        const filterVal = [
          "date",
          "branch_transactions",
          "agent_transactions",
          "total_transactions",
          "branch_sales",
          "agent_sales",
          "total_sales",
          "branch_commission",
          "agent_commission",
          "total_commission",
        ];
        const list = this.data;
        console.log("LIST", list);
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.getFileName(),
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      console.log(jsonData);
      return Object.keys(jsonData).map((v) => {
        console.log();
        return filterVal.map((j) => {
          console.log("J", j);
          console.log();

          if (j === "date") {
            return v;
          } else if (j === "total") {
            return (
              Number(jsonData[v]["amount"]) + Number(jsonData[v]["commission"])
            );
          } else {
            return jsonData[v][j];
          }
        });
      });
    },
  },
};
</script>
